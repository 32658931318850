import Footer from "../components/Footer/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SliderAreas from "../components/Slider/SliderAreas";
import SliderAreasInterno from "../components/Slider/SliderAreasInterno";
import Carousel from "react-bootstrap/Carousel";
import mask from "../assets/areas-assets/residencial/mask.jpg";
import { Col, Container, Row } from "react-bootstrap";
import residencial3 from "../assets/nosotros-assets/master.png";
import electricidad from "../assets/nosotros-assets/electricidad.svg";
import atencion from "../assets/nosotros-assets/atencion.svg";
import obras from "../assets/nosotros-assets/obras.svg";
import precios from "../assets/nosotros-assets/precios.svg";
import asesoria from "../assets/nosotros-assets/asesoria.svg";
import construccion from "../assets/nosotros-assets/construccion.svg";
import carousel1 from "../assets/areas-assets/residencial/carousel/carusel1.png";
import carousel2 from "../assets/areas-assets/residencial/carousel/carusel2.png";
import carousel3 from "../assets/areas-assets/residencial/carousel/carusel3.png";
import { Link } from "react-router-dom";
import arrowUp from "../assets/areas-assets/residencial/arrow-narrow-up.png";
import divisor from "../assets/areas-assets/residencial/divisor.png";
import SliderNosotros from "../components/Slider/SliderNosotros";
import consulta from "../assets/nosotros-assets/consulta.png";

const Nosotros = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // para un efecto de desplazamiento suave
    });
  };

  const ventajas = [
    { title: "Energía eléctrica trifásica subterránea.", img: electricidad },
    { title: "Operaciones sin comisión de venta.", img: atencion },
    { title: "Tendido de fibra óptica subterránea directa al hogar.", img: obras },
    { title: "Precios competitivos y planes de financiación.", img: precios },
    { title: "Asesoramiento personalizado en el armado del anteproyecto y construcción.", img: asesoria },
    { title: "Construcción en 40% del lote con retiro en las 4 caras.", img: construccion },
  ];
  return (
    <>
      <SliderNosotros />
      <div className="position-relative">
        <Container fluid className="pt-0 pb-md-5 pb-2 pt-md-0 pt-md-5 pt-3 boxTitleIntro introNosotros">
          <Container>
            <Row>
              <Col className="titleResidencialPrin pt-5 d-md-block d-none">
                <h1>Conocé nuestras propuestas</h1>
                <h2>Costayres</h2>
              </Col>

              <Col className="titleResidencialPrin pt-5 d-md-none d-block">
                <h3>Conocé nuestras propuestas</h3>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container className="pt-md-5 pb-5 mt-md-5 pt-0 mt-0">
        <Row className="pt-5">
          <Col md={6} xs={11} className="intro-residencial-txt pt-5 mt-5 mx-auto mb-md-0 mb-5">
            <p>
              Somos un mega emprendimiento inmobiliario con un masterplan innovador que integra distintos estilos de
              vida entre la naturaleza del bosque y el mar. <br />
              <br /> Nuestra planificación urbanística se destaca por sus amplios lotes residenciales de más de 1000 m²,
              trazas de calles que respetan la topografía natural del lugar y separación de áreas según su destino.
              <br />
              <br />
              Contamos con 12 barrios residenciales, un espacio multifamiliar para hoteles y apartamentos, zonas
              comerciales y áreas deportivas.
              <br />
              <br /> Nos enfocamos en brindar una atención personalizada para ayudarte a hacer realidad tu sueño de
              vivir entre el bosque y el mar.
            </p>
            <Row className="pt-5 pb-5">
              <Col>
                <Link to="/contacto">
                  <img src={consulta} alt="CostaAyres" className="img-fluid" />
                </Link>
              </Col>
            </Row>

            <Row className="pt-5">
              <Col md={4}>
                <p className="pt-2 pb-3" style={{ fontSize: "30px", color: "#3B3F49", fontFamily: "GothamBold" }}>
                  Descargar
                </p>
              </Col>
              <Col md={6}>
                <a
                  href="https://drive.google.com/file/d/1dK0_LREoBRReNUfSZivlZx2ckCDl5UyN/view?usp=drive_link"
                  target="_blank"
                  className="btn btn-planos-new"
                  rel="noreferrer"
                >
                  Masterplan
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <div>
              <img src={residencial3} alt="CostaAyres" className="img-fluid" style={{ borderRadius: "12px" }} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="boxVentajasNew p-5">
        <Container>
          <Row>
            <Col>
              <h6 className="pt-2 text-center pb-md-0 pb-4" style={{ fontFamily: "GothamMedium" }}>
                Ventajas exclusivas
              </h6>
            </Col>
          </Row>
          <Row>
            <Col className="p-md-5 p-1  pb-0">
              <div className="containerInterno">
                <Row className="p-md-5 p-3">
                  {ventajas.map((ventaja, index) => (
                    <Col md={6} key={index} className="p-4">
                      <div className="d-flex flex-row justify-content-start align-content-center align-items-center">
                        <img src={ventaja.img} alt="CostaAyres" className="img-fluid" />
                        <p>{ventaja.title}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Footer />
    </>
  );
};

export default Nosotros;
