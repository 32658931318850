import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../Logo/Logo";
import { Link, useLocation } from "react-router-dom";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import { scrollTo } from "../../utils/scrollTo";

function NavBar() {
  const location = useLocation();
  let { pathname } = location;

  return (
    <Navbar expand="lg">
      <Container fluid>
        <Link to="/">
          <Navbar.Brand>
            {" "}
            <Logo />
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0 navHeader" style={{ maxHeight: "180px" }} navbarScroll>
            <Link className={`nav-link ${pathname === "/" && "active"}`} to="/">
              Home
            </Link>

            <Link to="/nosotros" className={`nav-link ${pathname === "/nosotros" && "active"}`}>
              Sobre Nosotros
            </Link>

            <Link
              to="/areas"
              className={`nav-link ${
                (pathname === "/areas" ||
                  pathname === "/areas/residencial" ||
                  pathname === "/areas/comercial" ||
                  pathname === "/areas/multifamiliar") &&
                "active"
              }`}
            >
              Áreas
            </Link>
            <Link to="/contacto" className={`nav-link ${pathname === "/contacto" && "active"} pe=md-0 me-md-0`}>
              Contacto
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
