import { Button, Col, Form, Row } from "react-bootstrap";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import HubspotForm from "./HubSpotForm";

const Formulario = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setError("Ingresá un email válido.");
      return;
    }

    setLoading(true);
    setError(null);
    setSent(false);

    try {
      const result = await emailjs.send("costaayres", "costatemplate", formData, "user_9LvDgXfdZLXUCssd2jjnf");
      console.log(result.text);
      setSent(true);
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (err) {
      setError("Failed to send message: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col md={12} xs={11} className="pt-4 boxForm mx-auto">
        <HubspotForm />
        {/* <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} xs={12} className="pb-4">
              <Form.Group className="mb-3">
                <Form.Label>Nombre y apellido</Form.Label>
                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6} xs={12} className="pb-4">
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6} xs={12} className="pb-4">
              <Form.Group className="mb-3">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control type="text" name="phone" value={formData.phone} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={12} xs={12} className="pb-4">
              <Form.Group className="mb-3">
                <Form.Label>Mensaje</Form.Label>
                <Form.Control as="textarea" rows={3} name="message" value={formData.message} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={12} xs={12} className="pb-4 d-flex justify-content-md-start justify-content-center">
              <Button variant="primary" type="submit" disabled={loading} className="btn btn-black">
                {loading ? "Enviando tu mensaje...." : "Enviar mensaje"}
              </Button>
            </Col>
          </Row>
        </Form>
        {error && <div className="alert alert-danger">{error}</div>}
        {sent && (
          <div className="alert alert-success">
            Hemos recibido correctamente tu mensaje. Pronto nos comunicaremos contigo!
          </div>
        )} */}
      </Col>
    </Row>
  );
};

export default Formulario;
