import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Areas from "../pages/Areas";
import Ubicacion from "../pages/Ubicacion";
import Contacto from "../pages/Contacto";
import ScrollToTop from "../utils/ScrollToTop";
import WhatsAppFlotante from "../components/WhatsAppFlotante/WhatsAppFlotante";
import Residencial from "../pages/Residencial";
import Multifamiliar from "../pages/Multifamiliar";
import Comercial from "../pages/Comercial";
import Nosotros from "../pages/Nosotros";

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/nosotros">
          <Nosotros />
        </Route>
        <Route exact path="/areas">
          <Areas />
        </Route>
        <Route exact path="/areas/residencial">
          <Residencial />
        </Route>
        <Route exact path="/areas/multifamiliar">
          <Multifamiliar />
        </Route>
        <Route exact path="/areas/comercial">
          <Comercial />
        </Route>
        <Route exact path="/contacto">
          <Contacto />
        </Route>
      </Switch>
      <WhatsAppFlotante />
    </Router>
  );
};

export default Routes;
