import Footer from "../components/Footer/Footer";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SliderAreas from "../components/Slider/SliderAreas";
import SliderAreasInterno from "../components/Slider/SliderAreasInterno";
import Carousel from "react-bootstrap/Carousel";
import mask from "../assets/areas-assets/multifamiliar/mask.png";
import mask1920 from "../assets/areas-assets/multifamiliar/mask-1920.png";
import { Col, Container, Row } from "react-bootstrap";
import residencial3 from "../assets/areas-assets/multifamiliar/multifamiliar-lat.png";
import deportes from "../assets/areas-assets/multifamiliar/deportes.svg";
import mapa from "../assets/areas-assets/multifamiliar/mapa.svg";
import mar from "../assets/areas-assets/multifamiliar/mar.svg";
import paisaje from "../assets/areas-assets/multifamiliar/paisaje.svg";

import carousel1 from "../assets/areas-assets/multifamiliar/carrusel/1.png";
import carousel2 from "../assets/areas-assets/multifamiliar/carrusel/2.png";
import carousel3 from "../assets/areas-assets/multifamiliar/carrusel/3.png";
import { Link } from "react-router-dom";
import arrowUp from "../assets/areas-assets/residencial/arrow-narrow-up.png";
import divisor from "../assets/areas-assets/residencial/divisor.png";

const Multifamiliar = () => {
  const location = useLocation();

  const [maskImage, setMaskImage] = useState(window.innerWidth < 1366 ? mask : mask1920);

  useEffect(() => {
    const handleResize = () => {
      setMaskImage(window.innerWidth < 1366 ? mask : mask1920);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // para un efecto de desplazamiento suave
    });
  };

  const ventajas = [
    { title: "Amplios lotes con vista al mar.", img: paisaje },
    { title: "Espacios destinados a amenities.", img: deportes },
    { title: "Acceso directo al mar sin costanera.", img: mar },
    { title: "Complementado con unidades turísticas de playa.", img: mapa },
  ];
  return (
    <>
      <SliderAreasInterno style={"multifamiliarInterno"} title={"Multifamiliar"} />
      <div className="position-relative">
        <div className="text-center">
          <img src={maskImage} alt="CostaAyres" className="img-fluid" />
        </div>
        <Container fluid className="pt-0 pb-md-5 pb-2 pt-md-0 pt-md-5 pt-3 boxTitleIntro">
          <Container>
            <Row>
              <Col className="titleResidencialPrin d-md-block d-none">
                <h1>Qué ofrecemos en el área</h1>
                <h2>Multifamiliar</h2>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container className="pt-5 pb-5 mt-md-5 mt-0">
        <Row>
          <Col md={6} xs={11} className="intro-residencial-txt mx-auto">
            <h1 className="titleMobile d-md-none d-block">Qué ofrecemos en el área multifamiliar</h1>
            <p>
              Las impresionantes vistas al mar están priorizadas a propuestas de espacios pensados y diseñados para
              condominios premium, hoteles de categoría, apart hotels tradicionales o temáticos, y complejos de cabañas.
            </p>
            <p className="pt-4 pb-3" style={{ fontSize: "30px", color: "#3B3F49", fontFamily: "GothamBold" }}>
              Plano Multifamiliar
            </p>
            <div style={{ width: "300px" }}>
              <a
                href="https://drive.google.com/file/d/1KFvwkHAMkwZ7lyE5ad_-M5LRfzi6BFPU/view?usp=sharing"
                target="_blank"
                className="btn btn-planos-new"
                rel="noreferrer"
              >
                Descargar
              </a>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <img src={residencial3} alt="CostaAyres" className="img-fluid" style={{ borderRadius: "12px" }} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="boxVentajasNew p-5">
        <Container>
          <Row>
            <Col>
              <h6 className="pt-2 text-center pb-md-0 pb-4" style={{ fontFamily: "GothamMedium" }}>
                Ventajas exclusivas
              </h6>
            </Col>
          </Row>
          <Row>
            <Col className="p-md-5 p-1  pb-0">
              <div className="containerInterno">
                <Row className="p-md-5 p-3">
                  {ventajas.map((ventaja, index) => (
                    <Col md={6} key={index} className="p-4">
                      <div className="d-flex flex-row justify-content-start align-content-center align-items-center">
                        <img src={ventaja.img} alt="CostaAyres" className="img-fluid" />
                        <p>{ventaja.title}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="p-0">
        <Row className="p-0">
          <Col className="p-0 text-center">
            <Carousel>
              <Carousel.Item>
                <img src={carousel1} alt="CostaAyres" className="img-fluid" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carousel2} alt="CostaAyres" className="img-fluid" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carousel3} alt="CostaAyres" className="img-fluid" />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>

      <Container fluid className="boxContactoNew">
        <Container className="pt-5 pb-5 d-flex justify-content-center position-relative">
          <p>
            <Link to="../contacto">Contáctanos</Link> para coordinar una entrevista y visita al barrio.
          </p>

          <img
            src={arrowUp}
            alt="CostaAyres"
            className="arrowUpRes"
            onClick={scrollToTop}
            style={{ cursor: "pointer" }}
          />
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default Multifamiliar;
