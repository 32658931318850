import Footer from "../components/Footer/Footer";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SliderAreas from "../components/Slider/SliderAreas";
import SliderAreasInterno from "../components/Slider/SliderAreasInterno";
import Carousel from "react-bootstrap/Carousel";
import mask from "../assets/areas-assets/comercial/mask.jpg";
import mask1920 from "../assets/areas-assets/comercial/mask-1920.jpg";
import { Col, Container, Row } from "react-bootstrap";
import residencial3 from "../assets/areas-assets/comercial/comercial-small.png";
import arquitectura from "../assets/areas-assets/comercial/arquitectura.svg";
import icon6 from "../assets/areas-assets/comercial/espacio.svg";
import icon5 from "../assets/areas-assets/comercial/mapa.svg";
import icon4 from "../assets/areas-assets/comercial/arquitectura.svg";
import icon3 from "../assets/areas-assets/comercial/bosque.svg";
import icon2 from "../assets/areas-assets/comercial/plano.svg";
import icon1 from "../assets/areas-assets/comercial/urbano.svg";

import carousel1 from "../assets/areas-assets/comercial/carrusel/1.jpg";
import carousel2 from "../assets/areas-assets/comercial/carrusel/2.jpg";
import carousel3 from "../assets/areas-assets/comercial/carrusel/3.jpg";
import { Link } from "react-router-dom";
import arrowUp from "../assets/areas-assets/residencial/arrow-narrow-up.png";
import divisor from "../assets/areas-assets/residencial/divisor.png";

const Comercial = () => {
  const location = useLocation();

  const [maskImage, setMaskImage] = useState(window.innerWidth < 1366 ? mask : mask1920);

  useEffect(() => {
    const handleResize = () => {
      setMaskImage(window.innerWidth < 1366 ? mask : mask1920);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // para un efecto de desplazamiento suave
    });
  };

  const ventajas = [
    { title: "Planificación urbanística.", img: icon1 },
    { title: "Distribución armónica y sinérgica.", img: icon2 },
    { title: "Entorno natural.", img: icon3 },
    { title: "Estilo arquitectónico.", img: icon4 },
    { title: "Cercanía a Costa del Este: acceso permanente a sus servicios disponibles.", img: icon5 },
    { title: "Espacios para la ambientación adecuada a cada marca.", img: icon6 },
  ];
  return (
    <>
      <SliderAreasInterno style={"comercialInterno"} title={"Comercial"} />
      <div className="position-relative">
        <div className="text-center">
          <img src={maskImage} alt="CostaAyres" className="img-fluid" />
        </div>
        <Container fluid className="pt-0 pb-md-5 pb-2 pt-md-0 pt-md-5 pt-3 boxTitleIntro">
          <Container>
            <Row>
              <Col className="titleResidencialPrin d-md-block d-none">
                <h1>Grandes oportunidades</h1>
                <h2>Comerciales</h2>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container className="pt-5 pb-5 mt-md-5 mt-0">
        <Row>
          <Col md={6} xs={11} className="intro-residencial-txt mx-auto">
            <h1 className="titleMobile d-md-none d-block">Grandes oportunidades comerciales</h1>
            <p>
              Brindamos una oportunidad única para desarrollar tu proyecto en un área destinada al paseo comercial,
              donde la naturaleza se fusiona en armonía con una amplia gama de ofertas pensadas para un público
              distinguido.
            </p>
            <p>Posibles rubros pensados:</p>
            <p>
              <ul>
                <li>Gastronomía Resto bar Paseo de los artesanos (temático)</li>
                <li>Home & Art design (locales de decoración, ferretería, mueblerías)</li>
                <li>Indumentaria</li>
                <li> Patio saludable (dietéticas, peluquería, manicura, gym)</li>
                <li>Salón de eventos, reuniones artísticas y culturales, exposiciones</li>
                <li>Exposición de coches y vehículos</li>
                <li>Supermercados y proveedurías</li>
              </ul>
            </p>
            <p className="pt-4 pb-3" style={{ fontSize: "30px", color: "#3B3F49", fontFamily: "GothamBold" }}>
              Plano Comercial
            </p>
            <div style={{ width: "300px" }}>
              <a
                href="https://drive.google.com/file/d/1rYOKQHXDpBAYQGpFaYBh8DHgTmb6DS1A/view?usp=sharing"
                className="btn btn-planos-new"
                target="_blank"
                rel="noreferrer"
              >
                Descargar
              </a>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <img src={residencial3} alt="CostaAyres" className="img-fluid" style={{ borderRadius: "12px" }} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="boxVentajasNew p-5">
        <Container>
          <Row>
            <Col>
              <h6 className="pt-2 text-center pb-md-0 pb-4" style={{ fontFamily: "GothamMedium" }}>
                Ventajas exclusivas
              </h6>
            </Col>
          </Row>
          <Row>
            <Col className="p-md-5 p-1  pb-0">
              <div className="containerInterno">
                <Row className="p-md-5 p-3">
                  {ventajas.map((ventaja, index) => (
                    <Col md={6} key={index} className="p-4">
                      <div className="d-flex flex-row justify-content-start align-content-center align-items-center">
                        <img src={ventaja.img} alt="CostaAyres" className="img-fluid" />
                        <p>{ventaja.title}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="p-0">
        <Row className="p-0">
          <Col className="p-0 text-center">
            <Carousel>
              <Carousel.Item>
                <img src={carousel1} alt="CostaAyres" className="img-fluid" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carousel2} alt="CostaAyres" className="img-fluid" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carousel3} alt="CostaAyres" className="img-fluid" />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>

      <Container fluid className="boxContactoNew">
        <Container className="pt-5 pb-5 d-flex justify-content-center position-relative">
          <p>
            <Link to="../contacto">Contáctanos</Link> para coordinar una entrevista y visita al barrio.
          </p>

          <img
            src={arrowUp}
            alt="CostaAyres"
            className="arrowUpRes"
            onClick={scrollToTop}
            style={{ cursor: "pointer" }}
          />
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default Comercial;
