import React, { useState } from "react";
import imagenPrecarga from "../../assets/Home2/Home0.jpg"; // Asegúrate de tener esta imagen en tu proyecto

const VideoPreload = ({ videoBackgroundDesktop, currentIndex, image }) => {
  const [videoCargado, setVideoCargado] = useState(false);

  return (
    <>
      {/* {currentIndex > 0 ? (
        <img
          src={image}
          alt="CostaAyres"
          className="p-0 m-0 mx-auto"
          style={{
            position: "absolute",
            zIndex: "-1",
            objectFit: "cover",
            width: "100%",
            height: "100%",
            transition: "background-image 0.5s ease-in-out",
          }}
        />
      ) : (
        <>
          {!videoCargado && (
            <img
              src={imagenPrecarga}
              alt="Cargando..."
              className="p-0 m-0 mx-auto"
              style={{
                position: "absolute",
                zIndex: "-1",
                objectFit: "cover",
                width: "100%",
                height: "100%",
                transition: "background-image 0.5s ease-in-out",
              }}
            />
          )}
          <video
            autoPlay
            loop
            muted
            id="videodesktop"
            className={`p-0 m-0 ${!videoCargado ? "d-none" : ""}`}
            onLoadedData={() => setVideoCargado(true)}
          >
            <source src={videoBackgroundDesktop} type="video/mp4" />
          </video>
        </>
      )} */}
      <img
        src={image}
        alt="CostaAyres"
        className="p-0 m-0 mx-auto"
        style={{
          position: "absolute",
          zIndex: "-1",
          objectFit: "cover",
          width: "100%",
          height: "900px",
          transition: "background-image 0.5s ease-in-out",
        }}
      />
    </>
  );
};

export default VideoPreload;
