import { FaFacebook, FaInstagram, FaYoutube, FaRegEnvelope, FaLinkedinIn } from "react-icons/fa";

const Social = () => {
  return (
    <div>
      <ul className="list-unstyled list-inline socialList mb-0 d-flex justify-content-md-start justify-content-center ">
        <li className="list-inline-item ">
          <a href="https://www.facebook.com/share/TpntWVNNC7W8tckL/?mibextid=qi2Omg" target="_blank" rel="noreferrer">
            <FaFacebook size={29} className="linkSocial" />
          </a>
        </li>
        <li className="list-inline-item">
          <a href="https://www.instagram.com/costayres/" target="_blank" rel="noreferrer">
            <FaInstagram size={30} className="linkSocial" />
          </a>
        </li>
        <li className="list-inline-item">
          <a href="mailto:contacto@costayres.com" target="_blank" rel="noreferrer">
            <FaRegEnvelope size={31} className="linkSocial" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Social;
