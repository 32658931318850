import React, { useEffect, useState } from "react";

import logoWhatsapp from "../../assets/logoWhatsapp.png";
import "./whatsapp.scss";

const WhatsAppFlotante = () => {
  return (
    <div className="whatsapp-container">
      <a className="btn-whatsapp-flotante" href={"https://wa.me/541135677505"} target="_blank" rel="noreferrer">
        <img
          src={logoWhatsapp}
          alt="whatsapp"
          style={{ width: "50px", boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.9);" }}
        />
      </a>
    </div>
  );
};
export default WhatsAppFlotante;
