import Footer from "../components/Footer/Footer";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SliderAreas from "../components/Slider/SliderAreas";
import SliderAreasInterno from "../components/Slider/SliderAreasInterno";
import Carousel from "react-bootstrap/Carousel";
import mask from "../assets/areas-assets/residencial/mask.jpg";
import mask1920 from "../assets/areas-assets/residencial/mask-1920.jpg";
import { Col, Container, Row } from "react-bootstrap";
import residencial3 from "../assets/areas-assets/residencial/residencial3.png";
import aseoria from "../assets/areas-assets/residencial/asesoria.svg";
import bosque from "../assets/areas-assets/residencial/bosque.svg";
import comercial from "../assets/areas-assets/residencial/comercial.svg";
import construccion from "../assets/areas-assets/residencial/construccion.svg";
import deportes from "../assets/areas-assets/residencial/deportes.svg";
import mar from "../assets/areas-assets/residencial/mar.svg";
import carousel1 from "../assets/areas-assets/residencial/carousel/carusel1.png";
import carousel2 from "../assets/areas-assets/residencial/carousel/carusel2.png";
import carousel3 from "../assets/areas-assets/residencial/carousel/carusel3.png";
import { Link } from "react-router-dom";
import arrowUp from "../assets/areas-assets/residencial/arrow-narrow-up.png";
import divisor from "../assets/areas-assets/residencial/divisor.png";

const Residencial = () => {
  const location = useLocation();

  const [maskImage, setMaskImage] = useState(window.innerWidth < 1366 ? mask : mask1920);

  useEffect(() => {
    const handleResize = () => {
      setMaskImage(window.innerWidth < 1366 ? mask : mask1920);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // para un efecto de desplazamiento suave
    });
  };

  const ventajas = [
    { title: "Acceso directo al mar sin costanera.", img: mar },
    { title: "Área comercial.", img: comercial },
    { title: "Forestación de más de 35 años.", img: bosque },
    { title: "Asesoría personalizada post venta.", img: aseoria },
    { title: "Área deportiva.", img: deportes },
    { title: "Construcción en 40% del lote con retiro en las 4 caras.", img: construccion },
  ];
  return (
    <>
      <SliderAreasInterno style={"residencialInterno"} title={"Residencial"} />
      <div className="position-relative">
        <div className="text-center">
          <img src={maskImage} alt="CostaAyres" className="img-fluid" />
        </div>
        <Container fluid className="pt-0 pb-md-5 pb-2 pt-md-0 pt-md-5 pt-3 boxTitleIntro">
          <Container>
            <Row>
              <Col className="titleResidencialPrin d-none d-md-block">
                <h1>Planificación urbanística</h1>
                <h2>Costayres</h2>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container className="pt-md-5 pb-5 mt-md-5 pt-5 mt-0">
        <Row>
          <Col md={6} xs={11} className="intro-residencial-txt mx-auto">
            <h1 className="titleMobile d-md-none d-block">Planificación urbanística</h1>

            <p style={{ fontWeight: "500", fontFamily: "GothamMedium" }}>
              En nuestra área residencial, descubrirás 12 barrios con lotes que superan los 1000 m²
            </p>
            <p>
              Somos el destino ideal para hacer realidad tu sueño de vivir en armonía con la naturaleza, en donde tu
              casa no solo sea para vivir, sino para disfrutar.
            </p>
            <p className="pt-4 pb-3" style={{ fontSize: "30px", color: "#3B3F49", fontFamily: "GothamBold" }}>
              Descargá nuestros planos
            </p>
            <Row>
              <Col md={6} className="mb-4">
                <a
                  href="https://drive.google.com/file/d/1VbWJ5iKWJr3Jd597HYNaOdm9MOkgtDLq/view?usp=drive_link"
                  target="_blank"
                  className="btn btn-planos-new"
                  rel="noreferrer"
                >
                  Barrio La Pasión
                </a>
              </Col>
              <Col md={6} className="mb-4">
                <a
                  href="https://drive.google.com/file/d/10CB8SdS8dZTiFKUfEKZRAueTDdtY1DvJ/view?usp=drive_link"
                  target="_blank"
                  className="btn btn-planos-new"
                  rel="noreferrer"
                >
                  Barrio El Amor
                </a>
              </Col>
              <Col md={6} className="mb-4">
                <a
                  href="https://drive.google.com/file/d/1Dym0btZluhAdthp3ezg0i8e5zZ03uJnR/view?usp=drive_link"
                  target="_blank"
                  className="btn btn-planos-new"
                  rel="noreferrer"
                >
                  Barrio Norte
                </a>
              </Col>
              <Col md={6} className="mb-4">
                <a
                  href="https://drive.google.com/file/d/1tXmSn189bulg12_CjGbSyofJLnO83Bth/view?usp=drive_link"
                  target="_blank"
                  className="btn btn-planos-new"
                  rel="noreferrer"
                >
                  Barrio Escondido
                </a>
              </Col>
              <Col md={6} className="mb-4">
                <a
                  href="https://drive.google.com/file/d/1aNZ6wlYcNC-AH4QcnolRx0biP0fEpCBs/view?usp=drive_link"
                  target="_blank"
                  className="btn btn-planos-new"
                  rel="noreferrer"
                >
                  Barrio Encuentros
                </a>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col md={4}>
                <p className="pt-2 pb-3" style={{ fontSize: "30px", color: "#3B3F49", fontFamily: "GothamBold" }}>
                  Descargar
                </p>
              </Col>
              <Col md={6}>
                <a
                  href="https://drive.google.com/file/d/1dK0_LREoBRReNUfSZivlZx2ckCDl5UyN/view?usp=sharing"
                  target="_blank"
                  className="btn btn-planos-new"
                  rel="noreferrer"
                >
                  Masterplan
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <div>
              <img src={residencial3} alt="CostaAyres" className="img-fluid" style={{ borderRadius: "12px" }} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="boxVentajasNew p-5">
        <Container>
          <Row>
            <Col>
              <h6 className="pt-2 text-center pb-md-0 pb-4" style={{ fontFamily: "GothamMedium" }}>
                Ventajas exclusivas
              </h6>
            </Col>
          </Row>
          <Row>
            <Col className="p-md-5 p-1  pb-0">
              <div className="containerInterno">
                <Row className="p-md-5 p-3">
                  {ventajas.map((ventaja, index) => (
                    <Col md={6} key={index} className="p-4">
                      <div className="d-flex flex-row justify-content-start align-content-center align-items-center">
                        <img src={ventaja.img} alt="CostaAyres" className="img-fluid" />
                        <p>{ventaja.title}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="p-0">
        <Row className="p-0">
          <Col className="p-0 text-center">
            <Carousel>
              <Carousel.Item>
                <img src={carousel1} alt="CostaAyres" className="img-fluid" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carousel2} alt="CostaAyres" className="img-fluid" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carousel3} alt="CostaAyres" className="img-fluid" />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
      <Container fluid className="boxContactoNew">
        <Container className="pt-5 pb-5 d-flex justify-content-center position-relative">
          <p>
            <Link to="../contacto">Contáctanos</Link> para coordinar una entrevista y visita al barrio.
          </p>

          <img
            src={arrowUp}
            alt="CostaAyres"
            className="arrowUpRes"
            onClick={scrollToTop}
            style={{ cursor: "pointer" }}
          />
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default Residencial;
