import React, { createContext, useState } from "react";

export const CostaAyresContext = createContext();

const CostaAyresProvider = (props) => {
  const [section, setSection] = useState("home");
  return (
    <CostaAyresContext.Provider
      value={{
        section,
        setSection,
      }}
    >
      {props.children}
    </CostaAyresContext.Provider>
  );
};

export default CostaAyresProvider;
