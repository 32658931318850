import Footer from "../components/Footer/Footer";
import Slider from "../components/Slider/Slider";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);
  return (
    <>
      <Slider />

      <Footer />
    </>
  );
};

export default Home;
