import { Col, Container, Row } from "react-bootstrap";

import Logo from "../Logo/Logo";
import Social from "../NavBar/Social";
import { Link } from "react-router-dom";

import ScrollAnimation from "react-animate-on-scroll";
import logo from "../../assets/Home2/Logo-Footer.svg";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Footer = ({ footerSimple }) => {
  return (
    <Container fluid className={`boxFooter ps-0 pe-0 m-0 d-flex align-self-end`}>
      <Container fluid className={"bgNegro"}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="border-bottom-footer pb-4 mb-4">
                {" "}
                <Row>
                  <Col
                    md={4}
                    xs={12}
                    className="text-start flex-column d-flex justify-content-left align-content-top align-items-top"
                  >
                    <Link to="/" className={``}>
                      <img src={logo} alt="CostaAyres" />
                    </Link>
                    <p className="fraseFooter">Tu espacio entre el bosque y el mar.</p>
                  </Col>
                  <Col md={8} xs={12} className="d-flex justify-content-center justify-content-md-end pt-4 mt-1">
                    <div>
                      <ul className="list-unstyled contactList">
                        <li className="d-flex justify-content-start align-content-center align-items-center">
                          <FaPhone className="me-4" />{" "}
                          <a href="tel:0111535677505" style={{ color: "white" }}>
                            (+54 11) 3567 7505
                          </a>
                        </li>
                        <li className="d-flex justify-content-start align-content-center align-items-center">
                          <FaEnvelope className="me-4" />
                          <a href="mailto:contacto@costayres.com" style={{ color: "white" }}>
                            contacto@costayres.com
                          </a>
                        </li>
                        <li className="d-flex justify-content-start align-content-center align-items-center">
                          <div className="me-4">
                            <FaMapMarkerAlt />
                          </div>
                          <a
                            style={{ color: "white" }}
                            target="_blank"
                            href="https://www.google.com/maps?ll=-36.618707,-56.695887&z=16&t=m&hl=es-419&gl=AR&mapclient=embed&q=36%C2%B037%2707.3%22S+56%C2%B041%2736.0%22W+-36.618694,+-56.693333@-36.6186944,-56.6933333"
                            rel="noreferrer"
                          >
                            {" "}
                            Km. 333 de la Ruta 11 - Costa del Este, Buenos Aires, Argentina
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col>
                  <p className="text-center copyright">
                    © {new Date().getFullYear()}{" "}
                    <a href="https://www.avradev.com" target="_blank" rel="noreferrer">
                      Avra Dev
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default Footer;
