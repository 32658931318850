import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";
import React, { useCallback, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import buttonCard from "../../assets/areas-assets/residencial/ver.png";

const SliderAreasInterno = ({ title, style }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 800,
      behavior: "smooth", // para un efecto de desplazamiento suave
    });
  };

  return (
    <Container fluid className={`slider areasInterno d-flex flex-grow-1 flex-column ${style}`}>
      <Container className="d-flex flex-grow-1 h-100 flex-column">
        <Row className="d-flex flex-shrink-1">
          <Col>
            <NavBar />
          </Col>
        </Row>
        <Row className="d-flex flex-grow-1 p-4">
          <Col className="d-flex flex-column align-items-start align-content-start justify-content-between">
            <div className="w-100 flex-grow-1 d-flex flex-column align-items-start align-content-start justify-content-center">
              <div className="boxSliderLeft small">
                <h1>{title}</h1>

                <div className="d-flex justify-content-end pt-2">
                  <img src={buttonCard} className="img-fluid" onClick={scrollToTop} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default SliderAreasInterno;
