import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import videoBackgroundDesktop from "../../assets/Home2/Home0.mp4";
import VideoPreload from "./VIdeoPreload";
import { Link, useLocation } from "react-router-dom";
import image1 from "../../assets/Home2/Home1.jpg";
import image2 from "../../assets/Home2/Home2.jpg";
import image3 from "../../assets/Home2/Home3.jpg";

import buttonCard from "../../assets/areas-assets/button-card.png";

import bg from "../../assets/areas-assets/bg.jpg";

const SliderAreas = () => {
  const images = [videoBackgroundDesktop, image1, image2, image3];
  const history = useHistory();
  const handleOnClick = useCallback(() => history.push("/contacto"), [history]);
  const [showImage, setShowImage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [actualImage, setActualImage] = useState(images[0]);

  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setActualImage(images[currentIndex + 1]);
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setActualImage(images[currentIndex - 1]);
  };

  // useEffect to change the slider every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextImage, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [images]); // Dependency array ensures the effect is only applied once

  return (
    <Container fluid className="slider areas d-flex flex-grow-1 flex-column">
      {/* <div className="w-100 d-flex justify-content-center ">
        <img
          src={bg}
          alt="CostaAyres"
          className="p-0 m-0 mx-auto"
          style={{
            position: "absolute",
            zIndex: "-1",
            objectFit: "cover",
            width: "100%",
            height: "100%",
            transition: "background-image 0.5s ease-in-out",
          }}
        />
      </div> */}
      <Container className="d-flex flex-grow-1 h-100 flex-column">
        <Row className="d-flex flex-shrink-1">
          <Col>
            <NavBar />
          </Col>
        </Row>
        <Row className="d-flex flex-grow-1 p-4">
          <Col className="d-flex flex-column align-items-start align-content-start justify-content-between">
            <div className="w-100 flex-grow-1 d-flex flex-column align-items-start align-content-start justify-content-center">
              <div className="boxSliderLeft small">
                <h1>Más cerca</h1>
                <h2 className="pt-md-2 pt-0 pb-4">de la naturaleza y la vida de tus sueños</h2>
              </div>
              <Row className="w-100 ">
                <Col md={4} className="mb-md-0 mb-5">
                  <div className="boxAreaNew residencial w-100 d-flex justify-content-center align-content-end align-items-end">
                    <Link to="/areas/residencial" className="mb-3 pl-3 pr-3">
                      <img src={buttonCard} className="img-fluid btnVerMasmobile" />
                    </Link>
                  </div>
                </Col>
                <Col md={4} className="mb-md-0 mb-5">
                  <div className="boxAreaNew multifamiliar w-100 d-flex justify-content-center align-content-end align-items-end">
                    {" "}
                    <Link to="/areas/multifamiliar" className="mb-3 pl-3 pr-3">
                      <img src={buttonCard} className="img-fluid btnVerMasmobile" />
                    </Link>
                  </div>
                </Col>
                <Col md={4} className="mb-md-0 mb-5">
                  <div className="boxAreaNew comercial w-100 d-flex justify-content-center align-content-end align-items-end">
                    {" "}
                    <Link to="/areas/comercial" className="mb-3 pl-3 pr-3">
                      <img src={buttonCard} className="img-fluid btnVerMasmobile" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default SliderAreas;
