import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import videoBackgroundDesktop from "../../assets/Home2/Home0.mp4";
import VideoPreload from "./VIdeoPreload";
import arrowLarge from "../../assets/Home2/arrow-right-button.svg";
import arrowNarrowRight from "../../assets/Home2/arrow-narrow-right.svg";
import arrowNarrowLeft from "../../assets/Home2/arrow-narrow-left.svg";
import Social from "../NavBar/Social";
import { Link, useLocation } from "react-router-dom";
import image1 from "../../assets/Home2/Home1.jpg";
import image2 from "../../assets/Home2/Home2.jpg";
import image3 from "../../assets/Home2/Home3.jpg";
import image4 from "../../assets/Home2/Home4.jpg";

const Slider = () => {
  const images = [image1, image2, image3, image4];
  const history = useHistory();
  const handleOnClick = useCallback(() => history.push("/contacto"), [history]);
  const [showImage, setShowImage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [actualImage, setActualImage] = useState(images[1]);

  console.log(actualImage);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % images.length;
      setActualImage(images[newIndex]);
      return newIndex;
    });
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + images.length) % images.length;
      setActualImage(images[newIndex]);
      return newIndex;
    });
  };

  // useEffect to change the slider every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextImage, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [images]); // Dependency array ensures the effect is only applied once

  return (
    <Container fluid className="slider home d-flex flex-grow-1 flex-column">
      <VideoPreload videoBackgroundDesktop={videoBackgroundDesktop} image={actualImage} currentIndex={currentIndex} />
      <Container className="d-flex flex-grow-1 h-100 flex-column">
        <Row className="d-flex flex-shrink-1">
          <Col>
            <NavBar />
          </Col>
        </Row>
        <Row className="d-flex flex-grow-1">
          <Col className="d-flex flex-column align-items-start align-content-start justify-content-between">
            <div className="w-100 flex-grow-1 d-flex flex-column align-items-start align-content-start justify-content-center">
              <div className="boxSliderLeft">
                <h1>Tu espacio</h1>
                <h2 className="pt-md-4 pt-0 pb-4">entre el bosque y el mar</h2>
                <Link to="/nosotros" className="text-decoration-none">
                  <div className="d-flex justify-content-start justify-content-md-end flex-row arrowBox">
                    <p className="pe-3">Explorá</p>
                    <div className="pt-1">
                      <img src={arrowLarge} className="img-fluid" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="w-100 pb-4">
              <div className="d-flex justify-content-between w-100">
                <div>
                  <Social />
                </div>
                <div>
                  <button className="btn btn-transparent" onClick={prevImage}>
                    <img src={arrowNarrowLeft} />
                  </button>
                  <button className="btn btn-transparent" onClick={nextImage}>
                    <img src={arrowNarrowRight} />
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Slider;
