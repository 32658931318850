import { Accordion, Col, Container, Row } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import videoBackgroundDesktop from "../../assets/Home2/Home0.mp4";
import VideoPreload from "./VIdeoPreload";
import { Link, useLocation } from "react-router-dom";
import image1 from "../../assets/Home2/Home1.jpg";
import image2 from "../../assets/Home2/Home2.jpg";
import image3 from "../../assets/Home2/Home3.jpg";
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaRegEnvelope, FaMapMarked, FaMapMarkedAlt } from "react-icons/fa";
import buttonCard from "../../assets/areas-assets/button-card.png";

import bg from "../../assets/contacto-asset/slider.jpg";
import Formulario from "../Footer/Formulario";

const SliderContacto = () => {
  return (
    <Container fluid className="slider contactoNew d-flex flex-grow-1 flex-column">
      <Container className="d-flex flex-grow-1 h-100 flex-column">
        <Row className="d-flex flex-shrink-1">
          <Col>
            <NavBar />
          </Col>
        </Row>
        <Row className="d-flex flex-grow-1 p-4">
          <Col className="d-flex flex-column align-items-start align-content-start justify-content-between">
            <div className="w-100 flex-grow-1 d-flex flex-column align-items-start align-content-start justify-content-start">
              <div className="boxSliderLeft small pt-5">
                <h1>Conectate</h1>
                <h3 className="pt-md-2 pt-0 pb-4">con nosotros</h3>
              </div>
              <div className="w-100">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Dejá tu consulta</Accordion.Header>
                    <Accordion.Body>
                      <Formulario />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Nuestros datos de contacto</Accordion.Header>
                    <Accordion.Body>
                      <Row className="boxDatosContacto">
                        <Col md={11}>
                          <Row>
                            <Col md={4} className="pt-2">
                              <p className="mb-1" style={{ fontFamily: "GothamBold" }}>
                                <FaPhone className="me-2" /> Nuestro número
                              </p>
                              <a href="tel:0111535677505" style={{ color: "black" }}>
                                (+54 11) 3567 7505
                              </a>
                            </Col>
                            <Col md={4} className="pt-2">
                              <p className="mb-1" style={{ fontFamily: "GothamBold" }}>
                                <FaRegEnvelope className="me-2" /> Email
                              </p>
                              <p>
                                <a href="mailto:contacto@costayres.com" style={{ color: "#000" }}>
                                  contacto@costayres.com
                                </a>
                              </p>
                            </Col>
                            <Col md={4} className="pt-2">
                              <p className="mb-1" style={{ fontFamily: "GothamBold" }}>
                                <FaMapMarkedAlt className="me-2" />
                                Ubicación
                              </p>
                              <p>Km. 333 de la Ruta 11 Costa del Este, Bs. As., Arg</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default SliderContacto;
