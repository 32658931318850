import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

function SliderArea({ img }) {
  return (
    <ScrollAnimation animateIn="fadeIn">
      <Container>
        <Row>
          <Col>
            <Carousel>
              {img.map((slider) => (
                <Carousel.Item>
                  <img src={slider} alt="Costayres" />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </ScrollAnimation>
  );
}

export default SliderArea;
