import CostaAyresProvider from "./context/Provider";
import Routes from "./routes/Routes";
import "./styles/styles.scss";
import "animate.css/animate.min.css";

function App() {
  return (
    <CostaAyresProvider>
      <Routes />
    </CostaAyresProvider>
  );
}

export default App;
