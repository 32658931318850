import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import videoBackgroundDesktop from "../../assets/Home2/Home0.mp4";
import VideoPreload from "./VIdeoPreload";
import { Link, useLocation } from "react-router-dom";
import image1 from "../../assets/Home2/Home1.jpg";
import image2 from "../../assets/Home2/Home2.jpg";
import image3 from "../../assets/Home2/Home3.jpg";

import buttonCard from "../../assets/areas-assets/button-card.png";

import bgMobile from "../../assets/nosotros-assets/slider-mobile.jpg";

const SliderNosotros = () => {
  return (
    <Container fluid className="slider nosotrosNew d-flex flex-grow-1 flex-column">
      <Container className="d-flex flex-grow-1 h-100 flex-column">
        <Row className="d-flex flex-shrink-1">
          <Col>
            <NavBar />
          </Col>
        </Row>
        <Row className="d-flex flex-grow-1 p-4">
          <Col className="d-flex flex-column align-items-start align-content-start justify-content-between">
            <div className="w-100 flex-grow-1 d-flex flex-column align-items-start align-content-start justify-content-start">
              <div className="boxSliderLeft small nosotrosMargin">
                <h1>Sobre Nosotros</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default SliderNosotros;
