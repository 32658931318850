import React, { useEffect } from "react";

const HubspotForm = () => {
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.charset = "utf-8";
      script.type = "text/javascript";
      script.onload = () => {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "46512616",
          formId: "9d87ffee-19a5-42ca-b8ca-65a47deaa090",
          target: "#hubspotForm",
        });
      };
      document.body.appendChild(script);
    };

    if (!window.hbspt) {
      loadScript();
    } else {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "46512616",
        formId: "9d87ffee-19a5-42ca-b8ca-65a47deaa090",
        target: "#hubspotForm",
      });
    }
  }, []);

  return <div id="hubspotForm"></div>;
};

export default HubspotForm;
