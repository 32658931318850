import Footer from "../components/Footer/Footer";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SliderAreas from "../components/Slider/SliderAreas";
import SliderAreasInterno from "../components/Slider/SliderAreasInterno";
import Carousel from "react-bootstrap/Carousel";
import mask from "../assets/contacto-asset/mask.jpg";
import mask1920 from "../assets/contacto-asset/mask-1920.jpg";
import { Col, Container, Row } from "react-bootstrap";
import residencial3 from "../assets/areas-assets/residencial/residencial3.png";
import aseoria from "../assets/areas-assets/residencial/asesoria.svg";
import bosque from "../assets/areas-assets/residencial/bosque.svg";
import comercial from "../assets/areas-assets/residencial/comercial.svg";
import construccion from "../assets/areas-assets/residencial/construccion.svg";
import deportes from "../assets/areas-assets/residencial/deportes.svg";
import mar from "../assets/areas-assets/residencial/mar.svg";
import carousel1 from "../assets/areas-assets/residencial/carousel/carusel1.png";
import carousel2 from "../assets/areas-assets/residencial/carousel/carusel2.png";
import carousel3 from "../assets/areas-assets/residencial/carousel/carusel3.png";
import { Link } from "react-router-dom";
import arrowUp from "../assets/areas-assets/residencial/arrow-narrow-up.png";
import divisor from "../assets/areas-assets/residencial/divisor.png";
import SliderContacto from "../components/Slider/SliderContacto";
import botonVerMas from "../assets/contacto-asset/button.png";

const Contacto = () => {
  const location = useLocation();

  const [maskImage, setMaskImage] = useState(window.innerWidth < 1366 ? mask : mask1920);

  useEffect(() => {
    const handleResize = () => {
      setMaskImage(window.innerWidth < 1366 ? mask : mask1920);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // para un efecto de desplazamiento suave
    });
  };

  const ventajas = [
    { title: "Acceso directo al mar sin costanera.", img: mar },
    { title: "Área comercial.", img: comercial },
    { title: "Forestación de más de 35 años.", img: bosque },
    { title: "Asesoría personalizada post venta.", img: aseoria },
    { title: "Área deportiva.", img: deportes },
    { title: "Construcción en 40% del lote con retiro en las 4 caras.", img: construccion },
  ];
  return (
    <>
      <SliderContacto />
      <div className="position-relative">
        <div className="text-center">
          <img src={maskImage} alt="CostaAyres" className="img-fluid" />
        </div>
        <Container fluid className="pt-0 pb-md-0 pb-2 pt-md-0 pt-md-5 pt-3 boxTitleIntro">
          <Container>
            <Row>
              <Col md={11} className="titleResidencialPrin contacto mx-auto pt-5 mt-4">
                <h2>Costayres:</h2>
                <h3>una vida natural</h3>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container className="pt-5 pb-4 mt-5">
        <Row>
          <Col md={11} className="intro-residencial-txt mx-auto pt-5 ps-4 ps-md-0">
            <p>
              Queremos que seas parte de nuestro compromiso con la naturaleza y la vida sostenible. Estamos a
              disposición para responder todas tus preguntas y brindarte más información sobre Costayres.
            </p>
            <div className="text-md-start text-center pt-4">
              <Link to="/areas">
                <img src={botonVerMas} alt="CostaAyres" className="img-fluid" />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="boxVentajasNew p-5 pb-0">
        <Container>
          <Row>
            <Col>
              <h6 className="pt-2 text-center pb-md-0 pb-4" style={{ fontFamily: "GothamMedium" }}>
                Nuestra ubicación
              </h6>
            </Col>
          </Row>
          <Row>
            <Col className="p-md-5 p-1  pb-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3202.3197183927887!2d-56.695908222499014!3d-36.618690066691194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzbCsDM3JzA3LjMiUyA1NsKwNDEnMzYuMCJX!5e0!3m2!1ses-419!2sar!4v1703612564124!5m2!1ses-419!2sar"
                width="100%"
                height="500"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>

              <div className="text-end pt-5">
                <img
                  src={arrowUp}
                  alt="CostaAyres"
                  className="img-fluid"
                  onClick={scrollToTop}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Footer />
    </>
  );
};

export default Contacto;
