import Footer from "../components/Footer/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SliderAreas from "../components/Slider/SliderAreas";

const Areas = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);
  return (
    <>
      <SliderAreas />
      <Footer />
    </>
  );
};

export default Areas;
